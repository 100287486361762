<template>
  <v-container class="pt-0 mt-0">
    <v-row>
      <v-col cols="12" class="pt-0">
        <v-card :loading="loading" :disabled="loading" outlined>
          <v-card-title>
            <h3>Editar Fornecedor</h3>
            <v-spacer></v-spacer>
            <v-card-actions>
              <v-btn
                outlined
                class="primary"
                color="white"
                exact
                @click="openDialogAddSolicitation = true"
              >
                Cadastrar Tipo de Solicitações
              </v-btn>
              <v-btn
                  outlined
                  color="primary"
                  exact
                  :to="{ name: 'provider-list' }"
              >
                Voltar
              </v-btn>
            </v-card-actions>
          </v-card-title>
          <v-row>
            <v-card-text>
              <v-col cols="12">
                <v-form v-model="valid" ref="form" lazy-validation>
                  <v-text-field
                    v-model="getProvider.name"
                    label="Nome"
                    :rules="nameRules"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                  <v-text-field
                    v-model="getProvider.email"
                    label="E-mail destinatário"
                    :rules="emailRules"
                    outlined
                    dense
                    required
                  >
                  </v-text-field>
                  <v-autocomplete
                    v-model="getProvider.operadora_id_join"
                    :items="operadoras_join.data"
                    item-text="nome_operadora"
                    item-value="id_operadora"
                    outlined
                    dense
                    label="Operadora Join"
                    :loading="loading"
                    clearable
                  ></v-autocomplete>
                  <SolicitationTypeTagsComponent
                      v-if="!loading"
                      @save="saveTag"
                      :tags_selected="selected_tags"
                      :required="true"
                  ></SolicitationTypeTagsComponent>
                </v-form>
              </v-col>
              <v-col cols="12" align="right">
                <v-btn
                  color="primary"
                  @click="updateProviderItem"
                  :loading="creating"
                  :disabled="creating"
                >
                  Salvar
                </v-btn>
              </v-col>
            </v-card-text>
          </v-row>
        </v-card>
      </v-col>

      <div>
        <AddSolicitationTypeDialogComponent
          v-if="openDialogAddSolicitation"
          @closeDialog="closeDialogAddSolicitation"
          @saveDialog="saveAddSolicitation($event)"
          :loadingButtonDialog="loadingButtonDialog"
          :providerId="providerId"
        />
      </div>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters, mapActions, mapState } from "vuex";
import AddSolicitationTypeDialogComponent from "../../components/provider/AddSolicitationTypeDialogComponent.vue";
import SolicitationTypeTagsComponent from "../../components/solicitationType/SolicitationTypeTagsComponent.vue";

export default {
  components: {SolicitationTypeTagsComponent, AddSolicitationTypeDialogComponent},
  data() {
    return {
      loadingButtonDialog: false,
      openDialogAddSolicitation: false,
      providerId: "",
      getProvider: "",
      loading: true,
      creating: false,
      valid: true,
      tags: [],
      selected_tags: [],
      emailRules: [
        v => !!v || "E-mail obrigatório",
        v =>
          /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(v) ||
          "E-mail deve ser válido"
      ],
      nameRules: [v => !!v || "Nome obrigatório"]
    };
  },
  async mounted() {
    await this.loadComponent();
  },
  computed: {
    ...mapState("provider", ["provider_update","operadoras_join"]),
    ...mapState("configurationCompany", ["email_config"]),
    ...mapGetters("provider", ["getSelectedProvider"])
  },
  methods: {
    ...mapActions("provider", ["loadSelectedProvider", "updateProvider", "saveSolicitationTypeProvider", "loadOperadorasJoin", "updateTagsSolicitationType"]),
    ...mapActions("configurationCompany", ["loadEmailConfig"]),

    async loadComponent() {
      this.loading = true;
      await Promise.all([
        this.loadSelectedProvider({id: this.$route.params.id}),
        this.loadEmailConfig(),
        this.loadOperadorasJoin()
      ]).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      this.getProvider = this.getSelectedProvider;
      this.selected_tags = [...this.getProvider.tags];
      this.providerId = this.getProvider.id;
      this.loading = false;
    },

    saveTag(value) {
      this.tags = value;
    },

    areArraysEqual(array1, array2) {
    // Verifica se o comprimento dos arrays é o mesmo
    if (array1.length !== array2.length) {
      return false;
    }
    // Verifica se todos os IDs nos arrays são iguais
    for (let i = 0; i < array1.length; i++) {
      if (array1[i].id !== array2[i].id) {
        return false;
      }
    }
    return true;
    },

    async updateProviderItem() {
      if (this.$refs.form.validate()) {
        this.creating = true;
        const params = {
          operadora_id_join: this.getProvider.operadora_id_join,
          name: this.getProvider.name,
          email: this.getProvider.email,
          id: this.$route.params.id,
          tags: this.tags
        };
        await this.updateProvider(params).catch(error => {
          this.$swal("Oops...", error.message, "error");
        });
        if (this.provider_update.success) {

          if (this.areArraysEqual(this.tags, this.getProvider.tags)) {
            await this.$swal({
              icon: "success",
              title: this.provider_update.message,
              showCancelButton: true,
              confirmButtonText: "Ok",
              cancelButtonText: "Listagem",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#445E93",
              showLoaderOnConfirm: true
            }).then(result => {
              if (!result.value) {
                this.$router.push({
                  name: "provider-list"
                });
              }
            });
          } else {
            await this.$swal({
              icon: "success",
              title: "Registro atualizado com sucesso!",
              html: "Deseja atualizar Tipos de Solicitações com novas Tags adicionadas?",
              showCancelButton: true,
              confirmButtonText: "Sim",
              cancelButtonText: "Não",
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#445E93",
              showLoaderOnConfirm: true
            }).then(async result => {
              if (result.value) {
                await this.updateTagsSolicitationType(params).catch(error => {
                  this.$swal("Oops...", error.message, "error");
                });

                if (this.provider_update.success) {
                  await this.$swal(this.provider_update.message, '', "success");
                } else {
                  await this.$swal("Oops ...", this.provider_update.message, "warning");
                }
              }
            });
          }
        } else {
          await this.$swal("Oops ...", this.provider_update.message, "warning");
        }
        this.creating = false;
      }
    },
    async saveAddSolicitation(params) {
      await this.saveSolicitationTypeProvider(params).catch(error => {
        this.$swal("Oops...", error.message, "error");
      });

      await this.$swal("Tipos de Solicitações cadastradas com sucesso!", "", "success");
      this.closeDialogAddSolicitation();
      this.loadingButtonDialog = false;
    },
    closeDialogAddSolicitation() {
      this.openDialogAddSolicitation = false;
    }
  }
};
</script>
